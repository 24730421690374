<template>
  <div class="about">
    <div class="aboutBox">
      <span>广东安雅园新型材料科技有限公司</span>
      <p v-html="infoData.content" />
      <RouterLink tag="div" to="/about" class="button">
        了解更多 +
      </RouterLink>
    </div>
    <div class="vessel">
      <XgVideoPlayer :url="infoData.url" :poster="infoData.cover" />
    </div>
  </div>
</template>

<script>
import { companyIntro } from '@/api/about';
import XgVideoPlayer from '@/components/XgVideoPlayer';

export default {
  name: 'AboutIndex',
  components: {
    XgVideoPlayer,
  },

  data() {
    return {
      infoData: {},
    };
  },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await companyIntro();
      this.infoData = res.data[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .aboutBox {
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;
    color: rgb(0, 0, 0);
    span {
      display: block;
      font-family: Arial, "微软雅黑", "宋体";
      padding: 50px 0;
      color: #3f3f3f;
      font-size: 27px;
    }

    p {
      font-size: 14px;
      color: #6a6a6a;
      line-height: 1.8;
      font-weight: 300;
      text-align: justify;
    }
    .button {
      text-align: center;
      line-height: 25px;
      width: 114px;
      height: 30px;
      background-color: white;
      transition: all 0.2s;
      color: #7d7d7d;
      font-size: 14px;
      border: solid 2px #7d7d7d;
      margin: 50px 0;
      &:hover {
        cursor: pointer;
        background-color: #018835;
        transition: all 0.2s;
        color: #fff;
        font-size: 14px;
        border: solid 2px #000;
        transition: all 0.2s;
      }
    }
  }
  .vessel {
    margin-left: 50px;
    width: 500px;
    box-shadow: 10px 10px 5px #666;
  }
}
</style>
