<template>
  <div class="case">
    <div class="Box">
      <span class="Subtitle">1200+互联网合作案例</span>
      <span class="Title">经典案例</span>
      <div class="caseContent">
        <div
          v-for="(item, index) in hideBoxList.slice(0, 5)"
          :key="index"
          class="caseContentSonBox"
        >
          <div class="imgBox">
            <img :src="item.imgUrl" alt="">
          </div>
          <RouterLink to="/caseCenter">
            <div class="hideBox">
              <span>{{ item.caseTitle }}</span>
              <span>{{ item.description }}</span>
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseInfo } from '@/api/case';

export default {
  name: 'CaseIndex',
  data() {
    return {
      hideBoxList: [
        {
          caseTitle: '',
          description: '',
          imgUrl: '',
        },
      ],
    };
  },
  created() {
    this.getCaseInfo();
  },
  methods: {
    async getCaseInfo() {
      try {
        const params = {
          caseClassId: '',
          limit: 6,
          pages: 1,
        };
        const res = await caseInfo(params);
        this.hideBoxList = res.data.records;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.case {
  width: 100%;
  // height: 900px;
  .Box {
    .caseContent {
      width: 90%;
      height: 550px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      padding: 30px 0 0 540px;
      .caseContentSonBox {
        width: 240px;
        height: 240px;
        position: relative;
        margin-left: 20px;
        .imgBox {
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            transition: all 0.1s;
          }
        }
        .hideBox {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 999;
          opacity: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #0000009e;
          span:nth-child(1) {
            font-size: 14px;
            color: #fff;
            padding-bottom: 20px;
          }
          span:nth-child(2) {
            width: 90%;
            font-size: 12px;
            color: rgb(198, 198, 198);
          }
        }
        &:hover .hideBox {
          opacity: 1;
          transition: all 0.5s;
        }
        &:hover img {
          transform: scale(110%, 110%);
          transition: all 0.5s;
        }
      }
      .caseContentSonBox:first-child {
        width: 500px;
        height: 500px;
        position: absolute;
        left: 80px;
        top: 160px;
      }
    }
  }
}
</style>
