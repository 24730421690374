<template>
  <div class="vr">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">VR全景看厂</span>
      <span class="Chtitle">更加真实的了解我们，多视角感受</span>
    </div>
  
    <ElCarousel
      :interval="3000"
      indicator-position="none"
      type="card"
      height="500px"
      loop
      autoplay
    >
      <template v-for="(item, index) in picList">
        <ElCarouselItem :key="index">
          <a :href="item.url" target="_blank">
            <img :src="item.photo">
          </a>
        </ElCarouselItem>
      </template>
    </ElCarousel>
  </div>
</template>

<script>
import { vrbanner } from '@/api/home';

export default {
  name: 'VrIndex',
  data() {
    return {
      picList: [],
    };
  },
  created() {
    this.vrbanner();
  },
  methods: {
    async vrbanner() {
      try {
        const res = await vrbanner();
        this.picList = res.data.records;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.vr {
  background-color: rgb(250, 250, 250);
  padding-bottom: 100px;

}
.el-carousel {
  margin-top: 50px;
  padding: 0 60px;
  img {
    height: 100%;

    object-fit: cover;
  }
}
</style>
