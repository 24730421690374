<template>
  <!--id 我们的服务 -->
  <div class="serve">
    <div class="Box">
      <span class="Subtitle">OUR BUSINESS</span>
      <span class="Title">我们的业务</span>
      <div class="serveContent">
        <div
          v-for="item in serveList"
          :key="item.id"
          class="serveContentSonBox"
        >
          <span class="title">{{ item.name }}</span>
          <img :src="item.icon" class="img">
          <span class="content">{{ item.sketch }}</span>
          <hr>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <RouterLink tag="div" to="/serve" class="btn">
      <span>更多服务</span>
    </RouterLink>
  </div>
</template>

<script>
import { fetchServe } from '@/api/serve';

export default {
  name: 'ServerIndex',
  data() {
    return {
      serveList: [
        {
          id: '',
          name: '',
          sketch: ' ',
          icon: '',
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetchServe();
      this.serveList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>

.serve {
  width: 100%;
  padding-bottom: 25px;
  background-color: #f6f6f6;
  position: relative;
  .Box {
    .serveContent {
      width: 1200px;
      margin: 40px auto 0;
      height: 500px;
      background-color: #fff;
      box-shadow: 0 0 20px #00000020;
      display: flex;
      .serveContentSonBox {
        border: 1px solid #00000020;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 35px 20px 10px;
      }
    }
  }
}
.title {
  font-size: 26px;
  font-weight: bold;
  color: #585858;
  text-align: justify;
}
.img {
  width: 100%;
  transition: all 1s;
  overflow: hidden;
}
.img:hover {
  transform: scale(1.05);
  transition: all 0.5s;
}
.content {
  font-size: 14px;
  line-height: 1.8;
  color: #686868;
}

</style>
