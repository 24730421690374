<template>
  <div class="core">
    <div class="Box">
      <span class="Title">核心产品</span>
      <span class="Chtitle">全新生态板材 全屋多维应用</span>
    </div>
    <div class="core-middle">
      <div class="core-title">
        安雅园以装配式集成新饰材——碳晶板、竹木纤维成墙板为核心材集成墙板为核心材料，它不铲墙、不动土、裸墙直装，一板可装全屋天墙地柜门，装修工期省一半，装修工钱省一半，装修效果美轮美奂。饰材产品有仿非洲大理石、美国红橡，印度金丝楠、越南红木、缅甸紫檀、菲律宾香樟等千余种系列，效果类比同类产品更为实用和豪华，颠覆所有传统饰材，全面提升家居的安全性、舒适性、艺术性。
      </div>
      <img src="https://img.kcgeis.com/5447732415.png">
    </div>
    <div class="core-bottom">
      <template v-for="(item , index) in productList">
        <div :key="index" class="productList">
          <div class="product-title">
            {{ item.title }}
          </div>
          <div class="product-detail">
            {{ item.detail }}
          </div>
          <img class="product-img" :src="item.img">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCore',

  components: {},

  props: {},

  data () {
    return {
      productList: [
        {
          title: '01 生态板 真环保',
          detail: 'E0级环保标准，采用生态集成墙板，将环保材料天然碳晶板、竹木纤维成墙板为核心材与高分子膜合为一体，无污染无异味。',
          img: 'https://img.kcgeis.com/4739535924.png',
        },
        {
          title: '02 耐候强 易打理',
          detail: '板材耐酸碱、耐油污、耐水浸、易清洁，抗冲击，不膨胀，不褪色不变形不老化，使用寿命长久。',
          img: 'https://img.kcgeis.com/7218745964.png',
        },
        {
          title: '03 3D设计 快出图',
          detail: '采用目前先进的3D云设计软件，足不出户定制全屋整装解决方案，如同身临其境，视觉冲击更直观，所见即所得。',
          img: 'https://img.kcgeis.com/0379478902.png',
        },
        {
          title: '04 今天装 明天住',
          detail: '按照方案设计空间尺寸进行模块化定制，直接安装于毛坯房，无需现场裁切，减少装修污染，即装即住不用等。',
          img: 'https://img.kcgeis.com/6738967994.png',
        },
        {
          title: '05 产品多 配套全',
          detail: '全屋整装全产业链配套，千余款单品，风格多样，装修不用东奔西跑，安雅园一站式配齐。',
          img: 'https://img.kcgeis.com/6802423851.png',
        },
        {
          title: '06 成本低 效果好',
          detail: '全套饰材工厂定制，现场直接装配，省工省时，一体化工业设计，全屋风格统一，同等效果成本更省。',
          img: 'https://img.kcgeis.com/7941029597.png',
        },
        
      ],
    };
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.core {
  min-width: 1300px;
  display: flex;
  flex-direction: column;
  .core-middle {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 30px 0;
    .core-title {
      width: 580px;
      padding: 20px;
      color: #fff;
      background-color: #018835;
      height: 250px;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
  .core-bottom {
    padding: 0 5% 20px;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .productList {
      width: 370px;
      margin: 0 15px;
      margin-bottom: 20px;
      .product-title {
        border-bottom: 1px solid #b8b8b8;
        font-size: 28px;
        font-weight: 800;
        line-height: 50px;
      }
      .product-detail {
        height: 100px;
        line-height: 28px;
        font-size: 16px;
        color: #8a8a8a;
        margin-top: 20px;
      }
    }
  }
}
</style>
