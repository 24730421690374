<template>
  <div class="banner">
    <Swiper ref="mySwiper" :options="swiperOptions">
      <template v-for="(item, index) in picList">
        <SwiperSlide :key="index">
          <img :src="item.url" alt="" class="bannerImg">
        </SwiperSlide>
      </template>
    </Swiper>
  </div>
</template>

<script>

import { slideshow } from '@/api/home';


export default {
  name: 'BannerIndex',

  data() {
    return {
      picList: [],
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
      },
    };
  },
  created() {
    this.slideshow();
  },
  methods: {
    async slideshow() {
      try {
        const res = await slideshow();
        const { img } = res.data[0];
        this.picList = img && img.split(';').map(item => ({ url: item }));
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-color: #f6f6f6;
  color: #666;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .bannerImg {
    width: 100%;
  }
}
</style>
