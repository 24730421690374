<template>
  <div class="flow">
    <div class="Box">
      <!-- 标题 -->
      <span class="Subtitle">SERVICE PROCESS</span>
      <span class="Title">服务流程</span>
    </div>

    <div class="content">
      <div class="logo-list">
        <template v-for="(item, index) in picList">
          <div
            :key="index"
            class="pic"
            :class="{ isActive: currentIndex == index }"
            @mouseenter="mouseenter(index)"
          >
            <img :src="currentIndex == index ? item.imgUrls : item.imgUrl">
            <span>{{ item.txt }}</span>
          </div>
        </template>
      </div>
      <Transition
        name="slide-fade"
      >
        <div v-if="showDetail" class="detail">
          <img :src="contentList[currentIndex].imgUrl" alt="">
          <div class="right">
            <h2>
              {{ contentList[currentIndex].txt }}
            </h2>
            <span>{{ contentList[currentIndex].span }}</span>
            <RouterLink tag="div" to="/contactUs" class="button">
              <p>
                联系我们
              </p>
            </RouterLink>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlowIndex',
  data() {
    const picList = [
      {
        imgUrl: require('@/assets/icon/lc1.png'),
        imgUrls: require('@/assets/icon/lc1s.png'),
        txt: '沟通需求，免费设计草图',
      },
      {
        imgUrl: require('@/assets/icon/lc2.png'),
        imgUrls: require('@/assets/icon/lc2s.png'),
        txt: '确认合作签约合同',
      },
      {
        imgUrl: require('@/assets/icon/lc3.png'),
        imgUrls: require('@/assets/icon/lc3s.png'),
        txt: '方案设计',
      },
      {
        imgUrl: require('@/assets/icon/lc4.png'),
        imgUrls: require('@/assets/icon/lc4s.png'),
        txt: '结构设计',
      },
      {
        imgUrl: require('@/assets/icon/lc5.png'),
        imgUrls: require('@/assets/icon/lc5s.png'),
        txt: '精准预算',
      },
      {
        imgUrl: require('@/assets/icon/lc6.png'),
        imgUrls: require('@/assets/icon/lc6s.png'),
        txt: '工厂生产',
      },
      {
        imgUrl: require('@/assets/icon/lc7.png'),
        imgUrls: require('@/assets/icon/lc7s.png'),
        txt: '交付余款运输配送',
      },
      {
        imgUrl: require('@/assets/icon/lc8.png'),
        imgUrls: require('@/assets/icon/lc8s.png'),
        txt: '材料验收售后服务',
      },
    ];
    const contentList = [
      {
        imgUrl: require('@/assets/lct1.jpg'),
        txt: '沟通需求，免费设计草图',
        span:
          '前期和销售沟通需求，公司可以配合客户出具简易方案草图，帮助客户恰谈业务。',
      },
      {
        imgUrl: require('@/assets/lct2.jpg'),
        txt: '确认合作签约合同',
        span: '确定合作意向和公司签订采购合同。',
      },
      {
        imgUrl: require('@/assets/lct3.jpg'),
        txt: '方案设计',
        span:
          '建立微信沟通群，设计师根据客户需求进行方案设计，公司也可单独承接建筑方案设计业务。',
      },
      {
        imgUrl: require('@/assets/lct4.jpg'),
        txt: '结构设计',
        span:
          '设计方案通过后，下一步进入结构设计，公司也可单独承接VT拆图、结构深化等。',
      },
      {
        imgUrl: require('@/assets/lct5.jpg'),
        txt: '精准预算',
        span: '结构设计完成后进入精准预算阶段。',
      },
      {
        imgUrl: require('@/assets/lct6.jpg'),
        txt: '工厂生产',
        span:
          '预算结束之后和客户核实无误，即开始安排生产，公司也可承接来料代加工业务。',
      },
      {
        imgUrl: require('@/assets/lct7.jpg'),
        txt: '交付余款运输配送',
        span: '龙骨生产完成，客户支付余款，工厂即可安排装车配送至项目所在地。',
      },
      {
        imgUrl: require('@/assets/lct8.jpg'),
        txt: '材料验收售后服务',
        span: '材料送达后业主对所有材料进行清点和签收，然后进入售后服务阶段。',
      },
    ];
    return {
      picList,
      contentList,
      showDetail: false,
      currentIndex: null,
    };
  },

  methods: {
    mouseenter(index) {
      this.showDetail = true;
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>

.flow {
  margin-top: 50px ;

  display: inline-flex;
  flex-direction: column;
  background: linear-gradient(#00223e, #ffa17f);
  width: 100%;

}
.content {
  position: relative;
  margin: 50px auto;
  width: 1200px;
  display: inline-block;
}
.logo-list {
  display: flex;

}
.pic {
  position: relative;
  height: 180px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  img {
    width: 108px;
    height: 128px;
  }
  span {
    font-size: 13px;
    color: #fff;
    margin-bottom: 10px;
  }
}
.txt {
  position: absolute;
  top: 180px;
  background-color: rgb(255, 255, 255);
  height: 170px;
  width: 1200px;
}
.detail {
  padding: 10px;
  height: 230px;
  background: rgb(255, 255, 255);
  margin-top: 25px;
  width: 100%;
  display: flex;
  img {
    height: 100%;
  }
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 30px 50px;
    h2 {
      font-size: 24px;
      color: #018835;
    }
    span {
      font-size: 16px;
      color: #5d5d5d;
    }
    .button {
      width: 100px;
      height: 40px;
      border: 2px solid #018835;
      text-align: center;
      line-height: 37px;
      color: #018835;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        border: 2px solid #fff;
        text-align: center;
        line-height: 37px;
        background-color: #018835;
        color: #fff;
        transition: all 0.2s;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
