<template>
  <div class="home">
    <div class="homeBox">
      <!-- 轮播图 -->
      <Banner />
      <!-- 关于 -->
      <About />
      <!-- 我们的服务 -->
      <Serve />
      <!-- 经典案例 -->
      <CaseBox />
      <!-- 为什么选择我们 -->
      <Why />
      <!-- 核心产品 -->
      <Core />
      <!-- vr看图 -->
      <Vr />
      <!-- 公司相册 -->
      <Photo />
      <!-- 服务流程 -->
      <Flow />
      <!-- 新闻动态 -->
      <News />
    </div>
  </div>
</template>

<script>
import Banner from './components/HomeBanner.vue';
import Serve from './components/Serve.vue';
import CaseBox from './components/Case.vue';
import About from './components/About.vue';
import News from './components/News.vue';
import Why from './components/Why.vue';
import Core from './components/Core.vue';
import Vr from './components/Vr.vue';
import Photo from './components/TeamPhoto.vue';
import Flow from './components/Flow.vue';

export default {
  name: 'HomeIndex',
  components: {
    Banner,
    Serve,
    CaseBox,
    About,
    News,
    Why,
    Core,
    Vr,
    Photo,
    Flow,
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .homeBox {
    width: 100%;
  }
}
</style>
