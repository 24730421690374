<template>
  <div class="why">
    <div class="Box">
      <!-- 标题 -->
      <span class="Title">为什么选择安雅园建筑新型材料</span>
      <span class="Chtitle">值得信赖的装配式建筑综合服务商</span>
    </div>
    <!-- 内容 -->
    <div
      ref="testref"
      class="content"
      :class="{ boxleftTo: true, boxtestenterX: testShow }"
    >
      <ul>
        <li>
          <div class="small matter">
            <div class="a">
              <img :src="img[0].imgUrl" alt="">
            </div>
            <div class="txt">
              <h3>
                先进的设备
              </h3>
              <p>
                采用FrameMac，大禾众邦，鑫宏华三大设备系列，其中配备F1-C89十四台，F1-C140一台，MF-300一台。
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="small matter">
            <div class="txt">
              <h3>
                一站式采购
              </h3>
              <p>
                六大生产基地总仓储面积2.1万方，常备库存超千万，轻钢龙骨和全套辅材轻松一站式采购，集采更优惠、省时更省心。
              </p>
            </div>
            <div class="a">
              <img :src="img[1].imgUrl" alt="">
            </div>
          </div>
        </li>
        <li>
          <div class="big matter">
            <div class="a">
              <img :src="img[2].imgUrl" alt="">
            </div>
            <div class="txt">
              <h3>
                一对一方案定制
              </h3>
              <p>
                方案组为客户提供一对一的平面方案，业务范围涵盖了高档别墅、酒店会所、民宿建筑、生态厕所、景区构筑物等
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="small matter">
            <div class="txt">
              <h3>
                精准预算
              </h3>
              <p>
                公司具备完整且成熟的预算工作体系，大量的项目预算经验可以确保预算工作的准确性和高效性，为客户最大化的降低成本，增加利润。
              </p>
            </div>
            <div class="a">
              <img :src="img[3].imgUrl" alt="">
            </div>
          </div>
        </li>
        <li>
          <div class="small matter">
            <div class="a">
              <img :src="img[4].imgUrl" alt="">
            </div>
            <div class="txt">
              <h3>
                专业设计团队
              </h3>
              <p>
                安雅园建筑新型材料以技术与服务为后盾，产品有任何问题，公司将以设计团队为依托，快速研究处理，解决客户的后顾之忧。
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="small matter">
            <div class="txt">
              <h3>
                专业售后服务
              </h3>

              <p>
                安雅园建筑新型材料以技术与服务为后盾，产品有任何问题，公司将以设计团队为依托，快速研究处理，解决客户的后顾之忧。
              </p>
            </div>
            <div class="a">
              <img :src="img[5].imgUrl" alt="">
            </div>
          </div>
        </li>
        <li>
          <div class="big matter">
            <div class="a">
              <img :src="img[6].imgUrl" alt="">
            </div>
            <div class="txt">
              <h3>
                施工指导
              </h3>
              <p>
                针对施工过程出现的问题，公司可提供全方位的施工指导，帮助客户解决现场施工问题。
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="small matter">
            <div class="txt">
              <h3>
                快速出货
              </h3>
              <p>
                公司常备库存充足，多条生产线同时生产，订单处理能力强，可快速出货。
              </p>
            </div>
            <div class="a">
              <img :src="img[7].imgUrl" alt="">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 内容2 -->
    <div
      ref="testref2"
      class="details"
      :class="{ test: true, testenter: testShow2 }"
    >
      <div class="top">
        <div class="tp1">
          <p class="title">
            以技术和实力为后盾
          </p>
        </div>

        <div class="tp2">
          <img :src="img[8].imgUrl" alt="">
        </div>
        <div v-if="mode == 'auto'" class="tp3">
          <div class="txt">
            <h2 class="h2">
              <animate-number
                :mode="mode"
                class="h2"
                from="0"
                to="50"
                duration="2000"
              />
              +
            </h2>
            <p>
              50人的设计团队
            </p>
          </div>
          <div class="txt">
            <h2 class="h2">
              <animate-number
                :mode="mode"
                class="h2"
                from="0"
                to="3000"
                duration="2000"
              />+
            </h2>
            <p>
              3000+项目服务案例
            </p>
          </div>
        </div>
      </div>
      <div class="top">
        <div v-if="mode == 'auto'" class="tp3">
          <div class="txt">
            <h2 class="h2">
              <animate-number
                :mode="mode"
                class="h2"
                from="0"
                to="20000"
                duration="2000"
              />+
            </h2>
            <p>
              20000方标准化厂房
            </p>
          </div>
          <div class="txt">
            <h2 class="h2">
              <animate-number
                :mode="mode"
                class="h2"
                from="0"
                to="1400000"
                duration="2000"
              />+
            </h2>
            <p>
              六大生产基地年产量约140万方
            </p>
          </div>
        </div>
        <div class="tp2">
          <img :src="img[9].imgUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyIndex',

  data() {
    return {
      img: [
        { imgUrl: require('@/assets/why1.png') },
        { imgUrl: require('@/assets/why2.png') },
        { imgUrl: require('@/assets/why3.png') },
        { imgUrl: require('@/assets/why4.png') },
        { imgUrl: require('@/assets/why5.png') },
        { imgUrl: require('@/assets/why6.png') },
        { imgUrl: require('@/assets/why7.png') },
        { imgUrl: require('@/assets/why8.png') },
        { imgUrl: require('@/assets/why9.jpg') },
        { imgUrl: require('@/assets/why10.jpg') },
      ],
      mode: 'manual',
      testShow: false,
      testShow2: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset >= this.$refs.testref.offsetTop - 500) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.testShow = true;
      }
      if (window.pageYOffset >= this.$refs.testref2.offsetTop - 500) {
        // 当前滚动位置到达testref2的时候，显示div（100作为调整用）
        this.mode = 'auto';
        this.testShow2 = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.testenter {
  transform: translateY(0%) !important; //滚动后的位置
  opacity: 1 !important; //滚动后显现
  transition: all 0.5s ease;
}
.test {
  transform: translateY(10%); //滚动前的位置
  opacity: 0; //滚动前设置透明隐藏
}
//开始位置在左侧20%位置
.boxleftTo {
  transform: translateX(-20%); 
  opacity: 0; 
}
//滚动到指定位置时回到原点
.boxtestenterX {
  transform: translateX(0%) !important; //
  opacity: 1 !important; 
  transition: all 1s ease;
}
.why {
  margin-top: 70px;
  .Box {
    width: 100%;
    height: 230px;
    background-color: #018835;
    .Title {
      color: #fff;
    }
    .Chtitle {
      color: #fff;
    }
  }
  .content {
    margin: 20px auto 0;
    width: 1210px;
    height: 845px;
    img {
      width: 100%;
      transition: all 0.5s;
    }
    .matter {
      margin: 10px 0 0 10px;
      float: left;
      height: 406px;
      background: #f3f3f3;
      &:hover {
        background: #018835;
        img {
          transform: scale(1.1);
          transition: all 0.5s;
        }
        h3 {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }

    .small {
      width: 22%;
    }
    .big {
      width: 30%;
    }
    .txt {
      padding: 20px 25px;
      box-sizing: border-box;
      min-height: 195px;

      h3 {
        font-size: 24px;
        color: #018835;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: center;
      }
      p {
        font-size: 14px;
        color: #6c6c6c;
        line-height: 1.8;
        text-align: justify;
      }
    }
    .a {
      overflow: hidden;
    }
  }
  .details {
    position: relative;
    margin-top: 90px;
    background: linear-gradient(40deg, #dcdcdc 0%, #969696 100%);
    height: 865px;

    padding: 0 15%;
    h1 {
      font-size: 45px;
      color: #fff;
      font-weight: bold;
      position: absolute;
      top: 30px;
      left: 100px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 50px;
      .tp1 {
        padding: 10px;
        width: 125px;
        height: 435px;
        background: linear-gradient(40deg, #000 0%, #018835 100%);
        .title {
          writing-mode: tb-rl;
          color: #fff;
          font-size: 45px;
        }
      }
      .tp2 {
        width: 570px;
        height: 320px;
        img {
          height: 100%;
        }
      }
      .tp3 {
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .h2 {
          font-size: 90px;
          color: #55311e;
          font-weight: bold;
          line-height: 1;
        }
        p {
          font-size: 20px;
          color: #55311e;
          font-weight: bold;
          line-height: 1;
        }
      }
    }
  }
}
</style>
