<template>
  <div class="news">
    <div class="Box">
      <!-- 标题 -->
      <span class="Subtitle">POPULAR SCIENCE INFORMATION </span>
      <span class="Title">科普资讯</span>
      <!-- 按钮 -->
      <div class="newsBtn">
        <div
          v-for="item in newsBtn"
          :key="item.id"
          class="newsBtnBox"
          :class="item.id == newsBtnId ? 'btnTextColor' : ''"
          @click="onBtn(item.id)"
        >
          {{ item.newsClassName }}
          <div :class="item.id == newsBtnId ? 'btnColor' : ''" />
        </div>
      </div>
      <!-- 内容 -->
      <div class="newsContent">
        <div class="newsLeftBox">
          <div class="newsContentLeft">
            <div class="newsImgBox">
              <img :src="newsList[0].imgUrl" alt="">
            </div>
          </div>
        </div>
        <div class="newsRightBox">
          <div v-for="item in newsList" :key="item.id" class="newsRightForBox">
            <RouterLink :to="{ path: '/newsContent', query: { id: item.id } }">
              <span class="newsRightBoxTitle">{{ item.newsTitle }}</span>
              <span class="newsRightBoxTime">{{ item.createTime }}</span>
            </RouterLink>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <RouterLink
        tag="div"
        to="/news"
        class="btn"
      >
        <span>更多服务</span>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { newsClass, news } from '@/api/news';

export default {
  name: 'NewsIndex',
  data() {
    return {
      newsBtn: [],
      newsList: [{ imgUrl: '' }],
      id: '',
      newsBtnId: '',
    };
  },
  async created() {
    await this.getData();
    await this.getBtn();
  },
  methods: {
    async onBtn(e) {
      this.newsBtnId = e;
      this.getData();
    },
    async getBtn() {
      const res = await newsClass();
      this.newsBtn = res.data;
    },
    async getData() {
      const params = {
        newsClassId: this.newsBtnId,
        limit: 3,
        pages: 1,
      };
      const newsContent = await news(params);
      this.newsList = newsContent.data.records;
    },
  },
};
</script>

<style lang="scss" scoped>

.news {
  margin-top: 30px;
  width: 100%;
  .Box {
    width: 100%;
    margin: 0 auto 50px auto;
    position: relative;
    .newsTitle {
      font-weight: bold;
      padding: 110px 0 20px 0;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #333;
    }
    .newsBtn {
      display: flex;
      width: 600px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      margin: 50px auto;
      .newsBtnBox {
        cursor: pointer;
        font-size: 14px;
        flex: 1;
        border: 1px solid rgb(237, 237, 237);
        position: relative;
        &:nth-child(1) {
          border-right: none;
        }
        div {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .btnColor {
        border-top: 3px solid #018835;
        width: 100%;
      }
      .btnTextColor {
        color: #018835;
      }
    }
  }
  .newsContent {
    padding-top: 20px;
    height: 400px;
    display: flex;
    justify-content: center;
    .newsLeftBox {
      width: 500px;
      height: 230px;
      margin-right: 100px;
      .newsContentLeft {
        width: 100%;
        height: 100%;
        .newsImgBox {
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.4s transform;
            &:hover {
              transform: scale(1.1, 1.1);
            }
          }
        }
      }
    }
    .newsRightBox {
      .newsRightForBox {
        border-bottom: 1px solid rgb(237, 237, 237);
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
        width: 500px;
        .newsRightBoxTitle {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          color: #333;
          font-weight: bold;
          padding-bottom: 8px;
          font-size: 16px;
          &:hover {
            color: #018835;
          }
        }

        .newsRightBoxTime {
          width: 100%;
          display: flex;
          font-size: 16px;
          color: #999;
        }
      }
    }
  }
  
}
</style>
